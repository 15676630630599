import React, { useState } from 'react'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import BackToTopButton from '../components/BackToTopButton'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import AcademyExtras from '../components/AcademyExtras'
import PaymentModal from '../components/PaymentModal'
import Link from './LinkWrapper'
import ScrollOnAnimation from '../components/ScrollOnAnimation'
import i18n from '../locales/he.yaml'
import { prettyPrice } from '../utils'
import { fireEvent } from '../analytics'

const CourseTemplate = ({
  pageContext: data,
  location: { pathname, state }
}) => {
  const isMiluimBenefits = !!(state && state.isMiluimBenefits)

  const [isPaymentForm, setIsPaymentForm] = useState(false)
  const [backToTopSectionId] = useState(data.course.name.replaceAll(' ', '-'))

  const product = {
    category: data.course.greenInvoiceItemId,
    name: data.course.name,
    price: data.course.pricing.paymentPrice * data.course.pricing.numOfPayments
  }

  const fullPrice =
    data.course.pricing.paymentPrice * data.course.pricing.numOfPayments

  // let paymentPrice = data.course.pricing.paymentPrice

  const launchDiscountRate = 10
  let launchDiscountPrice = 0
  if (data.course.isInLaunch) {
    launchDiscountPrice = Math.floor(fullPrice * (launchDiscountRate / 100))
  }

  const fullPricePaymentsText = `${data.course.pricing.numOfPayments} ${data.pricingLabels.payments} * ${data.course.pricing.paymentPrice} ${data.pricingLabels.currency}`
  const fullPriceText = `(${data.pricingLabels.total} ${prettyPrice(
    data.course.pricing.paymentPrice * data.course.pricing.numOfPayments
  )} ${data.pricingLabels.currency})`

  const amount = fullPrice - launchDiscountPrice

  const pricePaymentsText = `${data.course.pricing.numOfPayments} ${
    data.pricingLabels.payments
  } * ${Math.ceil(amount / data.course.pricing.numOfPayments)} ${
    data.pricingLabels.currency
  }`
  const priceText = `(${data.pricingLabels.total} ${prettyPrice(amount)} ${
    data.pricingLabels.currency
  })`

  const courseTitlePrefix =
    data.course.category === 'דמויות'
      ? i18n.courses.figureCourseTitlePrefix
      : i18n.courses.courseTitlePrefix

  const CTA = () => (
    <a
      href='#registration'
      className='button is-nude is-medium'
      onClick={() =>
        fireEvent('InternalClick', product, {
          to: '#registration'
        })
      }
    >
      {i18n.courses.join}
    </a>
  )

  return (
    <Layout product={product}>
      <Seo
        metadata={{
          ...data.course.metadata,
          title: courseTitlePrefix + ' ' + data.course.metadata.title
        }}
        pathname={pathname}
        og={{
          image: getSrc(data.hero),
          alt: data.course.image.alt,
          video: data.course.vimeoId
            ? `https://vimeo.com/${data.course.vimeoId}`
            : null
        }}
        schemaMarkup={{
          breadcrumbs: [
            { name: i18n.header.academy, path: '/academy/' },
            { name: i18n.header.courses, path: '/academy/courses/' },
            {
              name: courseTitlePrefix + ' ' + data.course.name,
              path: `/academy/courses/${data.course.name.replaceAll(' ', '-')}/`
            }
          ],
          product: {
            name: courseTitlePrefix + ' ' + data.course.name,
            sku: data.course.name,
            description: data.course.title,
            brand: i18n.header.academy,
            image: getSrc(data.hero),
            path: `/academy/courses/${data.course.name.replaceAll(' ', '-')}/`,
            price: (
              data.course.pricing.numOfPayments *
              data.course.pricing.paymentPrice
            ).toString()
          }
        }}
      />
      {isPaymentForm && (
        <PaymentModal
          onClose={() => setIsPaymentForm(false)}
          welcomeImage={data.welcomeImage}
          course={data.course}
          coursePrefix={courseTitlePrefix}
          isAcademyCourse
        />
      )}
      <BackToTopButton selectorId={backToTopSectionId} />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                {!isMiluimBenefits && (
                  <li>
                    <Link to={'/academy/'}>{data.academyTitle}</Link>
                  </li>
                )}
                {!isMiluimBenefits && (
                  <li>
                    <Link to={'/academy/courses/'}>{data.coursesTitle}</Link>
                  </li>
                )}
                {isMiluimBenefits && (
                  <li>
                    <Link to={'/academy/security-forces-benefits/'}>
                      {i18n.header.securityForcesBenefits}
                    </Link>
                  </li>
                )}
                <li className='is-active'>
                  <a href='#' aria-current='page'>
                    {courseTitlePrefix + ' '}
                    {data.course.name}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column'>
                {data.course.isInLaunch ? (
                  <div className='columns is-mobile is-centered'>
                    <div className='column is-narrow'>
                      <div className='tags has-addons'>
                        <span className='tag is-nude'>
                          {data.course.isPreOrder
                            ? i18n.courses.preOrderLabelExtended
                            : i18n.courses.launchLabel}{' '}
                          {data.course.launchEndDate
                            ? `${i18n.courses.launchUntil} ${data.course.launchEndDate}`
                            : ''}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}
                <h2 className='is-size-5 has-text-weight-normal has-text-nude'>
                  {data.subtitle}
                </h2>
                <h1 className='is-size-4'>
                  {courseTitlePrefix + ' '}
                  {data.course.name}
                </h1>
                {data.course.category && data.course.level && (
                  <div className='columns is-mobile is-centered is-margin-top-6 is-margin-bottom-5'>
                    <div className='column is-narrow'>
                      <div className='tags has-addons'>
                        <span className='tag'>{i18n.courses.category}</span>
                        <span className='tag is-nude-light'>
                          {data.course.category}
                        </span>
                      </div>
                    </div>
                    <div className='column is-narrow'>
                      <div className='tags has-addons'>
                        <span className='tag'>{i18n.courses.level}</span>
                        <span className='tag'>
                          <span
                            className={clsx(
                              'course-level',
                              `level-${data.course.level}`
                            )}
                            style={{ top: '0.15rem' }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <p>
                  <CTA />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='columns is-multiline is-mobile is-centered'>
              <div className='column is-full-real-mobile is-10-mobile is-8-tablet is-5-desktop'>
                <GatsbyImage
                  image={getImage(data.hero)}
                  title={data.course.image.title}
                  alt={data.course.image.alt}
                  className='live-image'
                />
                <div className='is-flex justify-center content'>
                  <p className='has-text-centered has-text-gold-black'>
                    {data.course.image.title}
                  </p>
                </div>
              </div>
              <div className='column is-6-desktop is-full-tablet is-full-mobile is-offset-1-desktop'>
                <h3 className='is-size-5'>{data.course.title}</h3>
                <p>{data.course.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollOnAnimation>
        <div className='hero'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <CTA />
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <div className='hero'>
        <div className='hero-body is-padding-bottom-0 is-padding-top-0'>
          <hr className='has-background-nude-light' />
        </div>
      </div>
      <div id={backToTopSectionId} className='hero'>
        <div className='hero-body is-padding-bottom-0'>
          <div className='container content has-text-centered'>
            <ScrollOnAnimation>
              <h3 className='has-text-nude is-margin-bottom-7'>
                {data.featuresTitle}
              </h3>
            </ScrollOnAnimation>
            {data.course.vimeoId && (
              <ScrollOnAnimation>
                <div className='columns is-centered is-vcentered'>
                  <div className='column is-full-real-mobile is-12-tablet is-10-desktop is-8-widescreen'>
                    <figure
                      className='image is-16by9'
                      style={{ marginRight: 0, marginLeft: 0 }}
                    >
                      <iframe
                        className='has-ratio'
                        width='640'
                        height='360'
                        src={`https://player.vimeo.com/video/${data.course.vimeoId}?h=826545297f&badge=0&autopause=0player_id=0&app_id=58479`}
                        frameBorder='0'
                        allow='autoplay; fullscreen; picture-in-picture'
                      />
                    </figure>
                    <script src='https://player.vimeo.com/api/player.js'></script>
                  </div>
                </div>
              </ScrollOnAnimation>
            )}
          </div>
        </div>
      </div>
      {[
        data.course.bullets.slice(0, 3),
        data.course.bullets.slice(3, 6),
        data.course.bullets.slice(6, 9),
        data.course.bullets.slice(9, 12)
      ].map(
        (bullets, index) =>
          bullets.length > 0 && (
            <div key={index} className='hero'>
              <div className='hero-body'>
                <div className='container content'>
                  <div
                    className={clsx(
                      'columns is-mobile is-multiline is-centered is-vcentered',
                      {
                        'direction-row-reverse': index % 2 === 1
                      }
                    )}
                  >
                    <div
                      className={clsx(
                        'column is-full-mobile is-8-tablet is-6-desktop',
                        {
                          'is-offset-1-widescreen': index % 2 === 1
                        }
                      )}
                    >
                      {bullets.map((bullet) => (
                        <ScrollOnAnimation key={bullet.title}>
                          <div className='columns is-flex-direction-column-touch'>
                            <div className='column is-narrow has-text-centered-touch is-padding-bottom-0'>
                              <FontAwesomeIcon
                                icon={faHeart}
                                className='has-text-nude is-size-7 is-relative'
                                style={{ top: 4 }}
                              />
                            </div>
                            <div className='column'>
                              <h4 className='has-text-centered-touch'>
                                {bullet.title}
                              </h4>
                              <p>{bullet.description}</p>
                            </div>
                          </div>
                        </ScrollOnAnimation>
                      ))}
                    </div>
                    <ScrollOnAnimation>
                      <div
                        className={clsx(
                          'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen is-margin-top-7-touch',
                          {
                            'is-offset-1-widescreen': index % 2 === 0
                          }
                        )}
                      >
                        <GatsbyImage
                          image={getImage(data.images[index])}
                          title={data.course.images[index].title}
                          alt={data.course.images[index].alt}
                          className='live-image'
                        />
                        <div className='is-flex justify-center content'>
                          <p className='has-text-centered has-text-gold-black'>
                            {data.course.images[index].title}
                          </p>
                        </div>
                      </div>
                    </ScrollOnAnimation>
                  </div>
                </div>
              </div>
            </div>
          )
      )}
      <ScrollOnAnimation>
        <div id='registration' className='hero has-background-nude-light'>
          <div className='hero-body'>
            <div className='container has-text-centered content'>
              <h3>{data.investmentTitle}</h3>
              <div className='has-text-centered is-family-secondary block'>
                <span
                  className='is-block is-size-5 has-text-weight-bold'
                  style={{
                    textDecoration: data.course.isInLaunch
                      ? 'line-through'
                      : undefined
                  }}
                >
                  {fullPricePaymentsText}
                </span>
                <span
                  className='is-block'
                  style={{
                    textDecoration: data.course.isInLaunch
                      ? 'line-through'
                      : undefined
                  }}
                >
                  {fullPriceText}
                </span>
              </div>
              {data.course.isInLaunch && (
                <p className='is-italic'>
                  {i18n.courses.launchDiscountPrefix}{' '}
                  <span className='has-text-weight-bold is-family-secondary'>
                    % {launchDiscountRate}
                  </span>
                </p>
              )}
              {data.course.isInLaunch && (
                <>
                  <p>{i18n.courses.realInvestmentTitle}</p>
                  <div className='has-text-centered is-family-secondary block'>
                    <span className='is-block is-size-5 has-text-weight-bold'>
                      {pricePaymentsText}
                    </span>
                    <span className='is-block'>{priceText}</span>
                  </div>
                </>
              )}
              <button
                className='button is-nude is-medium'
                onClick={() => setIsPaymentForm(true)}
              >
                {data.callToAction}
              </button>
              <p className='help has-text-centered is-size-6 is-margin-top-6'>
                {i18n.courses.paymentInfo.pci}
              </p>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <AcademyExtras
        basicsCourseTitle={data.basicsCourseTitle}
        basicsCourseDescription={data.basicsCourseDescription}
        product={product}
        CTA={CTA}
        includeBasicsCourse={data.course.category !== 'דמויות'}
      />
    </Layout>
  )
}

export default CourseTemplate
